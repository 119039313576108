import container from '../container';

function resetPasswordAction(email, password, token) {
  return (dispatch) => {
    dispatch(resetPasswordRequest());
    container.api
      .resetPassword(email, password, token)
      .then((data) => {
        dispatch(resetPasswordSuccess(data));
        return { isSuccessful: true };
      })
      .catch((error) => {
        dispatch(resetPasswordError(error));
        return { isSuccessful: false };
      });
  };
}

function resetPasswordRequest() {
  return { type: 'RESET_PASSWORD_REQUEST' };
}

function resetPasswordSuccess(data) {
  return { type: 'RESET_PASSWORD_SUCCESS', data };
}

function resetPasswordError(error) {
  return { type: 'RESET_PASSWORD_ERROR', error };
}

export default resetPasswordAction;
